<template>
  <v-textarea
    :label="field.label"
    @input="setValueText"
    v-model="content"
    autofocus
    flat
    auto-grow
    solo
    shaped
  ></v-textarea>
</template>

<script>
export default {
  props: ["field"],
  data() {
    return {
      content: ""
    };
  },
  methods: {
    setValueText($event) {
      this.$emit("input", $event);
    }
  }
};
</script>
<style scoped>
.v-textarea {
  border: 1px solid #00000061;
}
</style>
