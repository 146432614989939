<template>
  <DatePicker
    value-type="HH:mm:ss"
    format="HH:mm"
    type="time"
    :title="field.label"
    :placeholder="field.label"
    style="width: 100%; height: 100%;"
    :show-hour="true"
    v-model="content"
    @change="setValueText"
  ></DatePicker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
export default {
  components: { DatePicker },
  props: ["field"],
  data() {
    return {
      content: ""
    };
  },
  methods: {
    setValueText($event) {
      this.$emit("input", $event);
    }
  }
};
</script>
<style scoped></style>
