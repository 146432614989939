var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.protocolsList)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.protocolsList,"sort-by":"calories","loading":_vm.loadingProtocols},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.title)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.title)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Adicionar ")])]}}],null,false,4285043759),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" Adicionar Protocolo")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Campo obrigatório'; }],"label":"Protocolo"},model:{value:(_vm.editedItem.protocol),callback:function ($$v) {_vm.$set(_vm.editedItem, "protocol", $$v)},expression:"editedItem.protocol"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":_vm.loadingButtonSave,"text":""},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Tem certeza que deseja deletar este Protocolo?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.loadingButtonDelete},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCreatedAt(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,2119748300)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }