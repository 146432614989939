<template>
  <v-text-field
      v-model="modelValue"
      :label="label"
      outlined
      dense
      ref="inputRef"
      @change="changeCurrencyValue(index)"
  ></v-text-field>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: ["options", "label", "index", "value"],
  data() {
    return {
      modelValue: "",
    };
  },
  mounted() {
    this.modelValue = this.value;
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);
    return { inputRef };
  },

  methods: {
    changeCurrencyValue(index) {
      this.$emit("changeCurrencyValue", index, this.modelValue);
    }
  },
};
</script>