<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">Cancelar Linhas</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      md="3"
      sm="12"
      v-for="(lineSelected, index) in lineSelect"
      :key="index"
    >
      <v-card outlined class="mx-auto">
        <v-card-title class="ma-0 pa-0">
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="lineSelected.fields[0].value"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id_linha"
                :rules="lineRules"
                :filter="customFilter"
                return-object
                @input="updateSelectedLine($event, lineSelect)"
                :disabled="loadingLinePackages"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  props: ["fields"],
  data() {
    return {
      packagesSelect: [],
      loading: true,
      show: false,
      lineSelect: {},
      lineRules: [v => !!v || "Linha é obrigatório"],
      loadingLinePackages: false
    };
  },
  async mounted() {
    // this.lineSelect = this.fields.data;
    this.lineSelect = this.fields.cancel_line;

    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
    this.updateSelectedLine(this.lineSelect);
  },

  computed: {
    ...mapState("packages", ["lines"]),
    ...mapGetters({
      name: "forms/getItemName",
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("interaction", ["updateFieldsPackages"]),
    ...mapActions("solicitation", ["updateSolicitationValidatePackagesValues"]),
    ...mapActions("packages", ["loadLines"]),
    ...mapMutations({
      setModel: "forms/setModelCancelLine"
    }),

    async clickAddNewLine() {
      const newLine = {
        fields: [{ value: "" }]
      };
      this.lineSelect = [...this.lineSelect, newLine];
    },

    updateSelectedLine(lineSelected) {
      let cancelLineList = [];
      this.loadingLinePackages = true;
      //  this.lineSelect.map((line) => {
      //   if (line.fields[0].value.id_linha) {
      //     cancelLineList.push({ line_id: line.fields[0].value.id_linha });
      //   } else {
      //     cancelLineList.push({ line_id: line.fields[0].value });
      //   }
      // });
      // this.show = this.show ? false : lineSelected;

      // if (cancelLineList.length) {
      //   this.updateValues(cancelLineList);
      // }

      lineSelected.map(line => {
        line.fields.map(lineId => {
          cancelLineList.push({ line_id: lineId.value });
        });
      });
      this.show = false;
      this.loadingLinePackages = false;
    },

    // setFieldValue(fields) {
    //   this.updateFieldsPackages(fields);
    // },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
