<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <v-card :loading="loading" :disabled="loading" outlined>
        <v-card-title>
          <h2>Interações</h2>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showLarge"
            outlined
            color="primary"
            :to="{ name: 'solicitation' }"
          >
            Voltar
          </v-btn>
          <v-btn
            v-else
            elevation="2"
            icon
            color="primary"
            :to="{ name: 'solicitation' }"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="pa-5">
          {{ selected_solicitation.description }}
          <span v-if="showLarge">- </span> <br v-else />
          <span class="email-tag">{{ selected_solicitation.tag }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-expansion-panels v-if="!showLarge" outlined>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Histórico de Interações
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col class="col-lg-4 col-md-6 col-sm-12 time-line">
                  <time-line v-if="!loading"></time-line>
                  <v-skeleton-loader
                    v-else
                    type="card-heading, paragraph"
                  ></v-skeleton-loader>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row>
            <v-col class="col-lg-8 col-md-6 col-sm-12">
              <new-form v-if="!loading"></new-form>
              <v-card class="mx-auto mt-5" outlined v-if="!loading">
                <v-card-title class="d-flex justify-space-between" style="cursor: pointer;" @click="showProtocolComponent = !showProtocolComponent">
                  <div>Protocolos da solicitação</div>
                  <v-icon>{{ showProtocolComponent ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-card-title>
                <v-expand-transition>
                  <div v-if="showProtocolComponent">
                    <SolicitationProtocolsComponent v-if="!loading"></SolicitationProtocolsComponent>
                  </div>
                </v-expand-transition>
              </v-card>
              <historic v-if="!loading" :is_adm="is_adm" class="mt-5"></historic>
              <v-skeleton-loader
                v-else
                type="table-heading, article, actions"
              ></v-skeleton-loader>
            </v-col>
            <v-col v-if="showLarge" class="col-lg-4 col-md-6 col-sm-12">
              <time-line v-if="!loading"></time-line>
              <v-skeleton-loader
                v-else
                type="card-heading, paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import NewForm from "@/components/interaction/interactionNewFormComponent";
import TimeLine from "@/components/interaction/interactionTimeLineComponent";
import Historic from "@/components/interaction/interactionHistoricComponent";
import SolicitationProtocolsComponent from "@/components/solicitation/SolicitationProtocolsComponent";

export default {
  components: {
    NewForm,
    TimeLine,
    Historic,
    SolicitationProtocolsComponent
  },
  data() {
    return {
      showProtocolComponent: false,
      selected_solicitation: {},
      loading: true,
      showLarge: true,
      is_adm: false
    };
  },

  async mounted() {
    await this.loadSelectedSolicitation(this.$route.params.id);
    if (this.selected_solicitation.success === false) {
      this.$swal("Oops...", this.selected_solicitation.message, "error");
    }

    this.selected_solicitation = this.getSelectedSolicitation;
    this.is_adm = this.selected_solicitation.is_adm;

    this.loading = false;
    this.getSize();
  },
  computed: {
    ...mapState("interaction", ["interactions"]),
    ...mapGetters("solicitation", ["getSelectedSolicitation"])
  },
  methods: {
    ...mapActions("solicitation", ["loadSelectedSolicitation"]),

    getSize() {
      let screenSize = window.screen.width;
      if (screenSize >= 700) {
        this.showLarge = true;
      } else {
        this.showLarge = false;
      }
    }
  }
};
</script>

<style scoped>
.time-line {
  padding: 0px;
}

.email-tag {
  font-size: 0.8rem;
}

@media (max-width: 700px) {
  .v-expansion-panel {
    border: thin solid rgba(0, 0, 0, 0.12) !important;
  }

  .v-expansion-panel::before {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .v-expansion-panel-content {
    padding: 0px;
    margin: 0px;
  }

  .v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }

  .email-tag {
    font-size: 0.6rem;
  }
}
</style>
