<template>
  <v-row class="mx-auto">
    <v-col v-for="(ddd, index) in field.data[0]" :key="index" cols="12" md="6">
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" title="Troca DDD">
          <v-row>
            <v-col cols="12" lg="8">
              {{ ddd.line.ddi }} ({{ ddd.line.ddd }}) {{ ddd.line.nr_linha }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-col class="col-sm-12 col-md-12 col-lg-12">
            <div v-if="loading">
              <v-skeleton-loader type="heading"></v-skeleton-loader>
            </div>
            <!-- Linha -->
            <v-autocomplete
              v-model="ddd.line"
              :items="[ddd.line]"
              item-text="nr_linha"
              item-value="id_linha"
              readonly
              outlined
              dense
              label="Linha"
              v-if="!loading && ddd.line"
              :rules="!!ddd.line.required ? required : []"
              @change="
                updatePackagesFromLine($event, ddd.line);
                setFieldValue(ddd, index);
              "
              :filter="customFilter"
              :disabled="loadingPackages"
              :loading="loadingPackages"
            >
              <template v-slot:selection="data">
                {{ data.item.ddi }} ({{ data.item.ddd }})
                {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.ddi }} ({{ data.item.ddd }})
                      {{ data.item.nr_linha }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.nome_completo"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <!-- DDD -->
            <v-autocomplete
                dense
                outlined
                readonly
                required
                label="DDD"
                v-model="ddd.ddd"
                :items="[ddd.ddd]"
                item-text="code"
                item-value="code"
                v-if="!loading && ddd.ddd"
                @change="setFieldValue(ddd, index)"
            >
              <template v-slot:selection="data">
                {{ data.item.code }} - {{ data.item.region }}
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content
                      v-text="data.item"
                  ></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                        v-html="data.item.code"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-html="data.item.region"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <!-- ICCID -->
            <v-text-field
              label="ICCID"
              v-model="ddd.iccid.iccid"
              outlined
              readonly
              dense
              v-if="!loading && ddd.iccid && isChangeDDD"
              @input="setFieldValue(ddd, index)"
            />
            <!-- Conta -->
            <AccountField
              v-if="!loading && ddd.conta && isChangeDDD"
              :readonly="true"
              :selectedAccount="ddd.conta"
            ></AccountField>

            <LineReference
              v-if="!loading && isChangeDDD"
              :selectedLine="ddd.linha_referencia"
              :readonly="true"
            ></LineReference>
            <!-- Nova Pessoa -->
            <v-checkbox
              :label="'Deseja vincular a uma nova pessoa?'"
              v-model="ddd.person.checkbox"
              class="mt-0"
              dense
              v-if="!loading && ddd.person.checkbox && isChangeDDD"
              readonly
            ></v-checkbox>
            <v-autocomplete
              dense
              outlined
              readonly
              required
              label="Nova Pessoa"
              v-model="ddd.person"
              :items="[ddd.person]"
              item-text="nome_completo"
              item-value="id_pessoa"
              v-if="!loading && ddd.person && ddd.person.checkbox && isChangeDDD"
              @change="setFieldValue(ddd, index)"
            >
            </v-autocomplete>
            <!-- Nova Linha -->
            <v-text-field
              label="Nova Linha"
              v-model="ddd.new_line.value"
              outlined
              dense
              v-if="!loading && ddd.new_line"
              @input="setFieldValue(ddd, index)"
              v-mask="mask"
            />
          </v-col>
          <!-- Data e hora -->
          <SuspendLineDateFields
            v-if="!loading"
            :type="field.name"
            :index="index"
            :default_date="ddd.date[0].value"
            :default_hour="ddd.date[1].value"
            @changeDateValues="updateDate"
          />

          <v-col class="col-sm-12 col-md-12 col-lg-12">
            <v-alert
                border="left"
                class="text-center"
                dense
                type="warning"
                v-if="loading"
                dark
            >Carregando pacotes da linha!
            </v-alert>
            <template v-if="ddd.packages.data && !loading">
              <v-alert border="left" color="indigo" dark dense class="mb-0">
                Pacotes da linha:
              </v-alert>
              <v-list>
                <template v-for="(linePackage, index) in ddd.packages.data">
                  <v-list-item :key="linePackage.nome">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ linePackage.nome }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                      v-if="index < ddd.packages.data.length - 1"
                      :key="index"
                  ></v-divider>
                </template>

                <template v-if="ddd.packages.data == ''">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Não possui
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </template>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import SuspendLineDateFields from "../customFieldsComponents/SuspendLineDateFields.vue";
import AccountField from "@/components/fields/AccountField.vue";
import LineReference from "@/components/fields/LineReference.vue";

export default {
  components: { LineReference, AccountField, SuspendLineDateFields },
  props: ["field"],
  data() {
    return {
      loading: true,
      loadingPackages: false,
      saveValid: false,
      isChangeDDD: false,
      haveDate: false,
      haveHour: false,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`],
      mask: ["(",/\d/,/\d/,") ",/\d/,/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/],
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.isChangeDDD = (this.field.type === 'ChangeDDD' && this.field.name === 'troca_ddd') ?? true;
    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["packages", "lines"]),
    ...mapGetters({
      name: "forms/getItemName",
      linePackages: "packages/getLinePackages"
    }),
    ...mapState("persons", ["persons"]),
    ...mapState("ddd", { dddList: "ddd" }),
    ...mapState("plans", ["account_line"])
  },
  methods: {
    ...mapMutations({ setSave: "interaction/setSave" }),
    ...mapActions("interaction", ["updateFieldsChangeDDD","setAutomationComponentFields"]),
    ...mapActions("packages", [
      "loadPackages",
      "loadLines",
      "loadLinePackages"
    ]),
    ...mapActions("persons", ["loadPersons"]),
    async updatePackagesFromLine(line, field) {
      this.loadingPackages = true;
      if (line) {
        await this.loadLinePackages({ id_linha: line }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        field.packages = this.linePackages;
      }
      this.loadingPackages = false;
    },
    setFieldValue(data, name) {
      this.setAutomationComponentFields({
        automation: "troca_ddd",
        data: this.field.data[0],
      });
    },
    customFilter(item, queryText) {
      const textOne = item.nr_linha.toLowerCase();
      const textTwo = item.nome_completo.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    updateDate(data) {
      this.field.data[0][data.index].date[0].value = data.initialDate;
      this.field.data[0][data.index].date[1].value = data.finishHour;
      this.updateFieldsChangeDDD({
        value: this.field.data[0][data.index],
        name: data.index
      });
    }
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
