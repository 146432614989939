<template>
  <v-file-input
    :label="field.label"
    append-icon="mdi-paperclip"
    truncate-length="50"
    prepend-icon=""
    counter
    multiple
    show-size
    filled
    flat
    dense
    @change="onFileSelected"
  ></v-file-input>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ["field", "page"],
  data() {
    return {
      value: ""
    };
  },
  methods: {
    ...mapMutations({
      setModel: "forms/setModelText"
    }),
    ...mapGetters({ getItemText: "forms/getItemText" }),
    setValueText() {
      this.setModel({ text: this.value, value: this.value });
    },
    onFileSelected(event) {
      this.$emit("input", event);
    }
  },
  created() {
    this.onFileSelected({})
  },
};
</script>
<style scoped>
.v-input__slot,
.v-text-field__slot,
.v-file-input__text {
  border: 1px solid #00000061;
  border-radius: 0px;
  border-top: 0px;
}

.v-text-field__slot::before {
  border: 1px solid red;
}
</style>
