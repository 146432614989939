<template>
  <v-row class="mx-auto">
    <v-col v-for="(plan, index) in plans.data[0]" :key="index">
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="index">
          <div class="headerClass">
            {{ index }}
          </div>
        </v-card-title>
        <v-card-text>
          <div v-for="(field, index2) in plan" :key="index2">
            <v-divider
              class="mb-5"
              v-if="index2 > 0 && field.type === 'linha_adiciona'"
            ></v-divider>
            <v-text-field
              :class="
                index2 === 0 && field.type === 'linha_adiciona' ? 'mt-5' : ''
              "
              dense
              outlined
              cols="10"
              v-if="field.type !== 'id_plan'"
              v-model="field.value"
              :rules="contaRules"
              :label="field.label"
              :disabled="
                field.type === 'conta_adiciona' || field.type === 'ddd_adiciona'
              "
              @input="setFieldValue(index, plan)"
            ></v-text-field>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["plans"],
  data() {
    return {
      contaRules: [v => !!v || "Conta é obrigatório"],
      dddRules: [v => !!v || "DDD é obrigatório"],
      linhasRules: [
        v =>
          (v && v >= 0) || "A quantidade de linhas não pode ser menor que zero"
      ]
    };
  },
  computed: {},
  methods: {
    ...mapActions("interaction", ["updateFieldsPlans"]),
    setFieldValue(plan_name, plan) {
      this.updateFieldsPlans({ fields: plan, plan_name: plan_name });
    }
  },
  mounted() {}
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
