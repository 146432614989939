<template>
  <v-data-table
    v-if="protocolsList"
    :headers="headers"
    :items="protocolsList"
    sort-by="calories"
    class="elevation-1"
    :loading="loadingProtocols"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
        <v-divider v-if="title"
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              Adicionar
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5"> Adicionar Protocolo</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                    <v-text-field
                      v-model="editedItem.protocol"
                      :rules="[v => !!v || 'Campo obrigatório']"
                      label="Protocolo"
                    ></v-text-field>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" :loading="loadingButtonSave" text @click="save">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">Tem certeza que deseja deletar este Protocolo?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="loadingButtonDelete">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ formatCreatedAt(item.created_at) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  props: ["title"],
  data: () => ({
    loadingProtocols: true,
    loadingButtonDelete: false,
    loadingButtonSave: false,
    solicitation_id: '',
    teste: true,
    dialog: false,
    protocolsList: [],
    dialogDelete: false,
    headers: [
      { text: 'Protocolo', value: 'protocol' },
      { text: 'Data de criação', value: 'created_at' },
      { text: '', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      protocol: '',
    },
    defaultItem: {
      protocol: '',
    },
  }),

  async mounted() {
    await this.loadProtocolsList();
    this.solicitation_id = this.$route.params.id;
  },

  watch: {
    dialog (val) {
      val || this.close();
    },
    dialogDelete (val) {
      val || this.closeDelete();
    },
  },

  computed: {
    ...mapState("solicitation", ['protocols']),
  },
  methods: {
    ...mapActions("solicitation", ["loadProtocols", "saveProtocol", "deleteProtocol"]),

    async loadProtocolsList () {
      this.loadingProtocols = true;
      await this.loadProtocols({
        id: this.$route.params.id
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.protocolsList = this.protocols;
      this.loadingProtocols = false;
    },

    deleteItem (item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm () {
      this.loadingButtonDelete = true;
      await this.deleteProtocol(this.editedItem).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.closeDelete();
      this.loadingButtonDelete = false;
      await this.loadProtocolsList();
    },

    close () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      })
    },

    formatCreatedAt(datetime) {
      const date = new Date(datetime);
      return date.toLocaleString("pt-BR");
    },

    closeDelete () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      })
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.loadingButtonSave = true;
        const payload = {
          'protocol': this.editedItem.protocol,
          'solicitation_id': this.solicitation_id
        }
        await this.saveProtocol(payload).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        this.close();
        this.loadingButtonSave = false;
        await this.loadProtocolsList();
      }
    },
  },
}
</script>
<style scoped></style>
