<template>
  <v-row class="mx-auto">
    <v-col
      v-for="(componentData, index) in lineSelect"
      :key="index"
      cols="12"
      md="6"
    >
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="index">
          <v-row>
            <v-col cols="12" lg="12"> </v-col>
            <v-col cols="12" lg="4">
              <date-picker
                v-model="componentData.deactivationDate"
                value-type="DD/MM/YYYY"
                format="DD/MM/YYYY"
                placeholder="Data de desativação"
                style="width: 100%"
                title="Data de desativação"
                :editable="false"
                :disabled="loading"
                :close="
                  componentData.deactivationDate
                    ? (componentData.showErrorMessage = false)
                    : (componentData.showErrorMessage = true)
                "
                @change="updateDate(index, componentData)"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-alert
                type="error"
                dense
                class="mb-0"
                v-if="
                  typeof componentData.showErrorMessage != 'undefined' &&
                    componentData.showErrorMessage &&
                    !loading
                "
                >Data de desativação é obrigatório</v-alert
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <div v-if="loading">
                <v-skeleton-loader type="heading"></v-skeleton-loader>
              </div>
              <v-autocomplete
                v-model="componentData.fields[0].value"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id_linha"
                outlined
                dense
                label="Linha"
                readonly
                v-if="!loading"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="6">
              <v-alert
                border="left"
                class="text-center"
                dense
                type="warning"
                v-if="loading"
                dark
                >Carregando pacotes da linha!
              </v-alert>
              <template v-if="componentData.fields[0].packages && !loading">
                <v-alert border="left" color="indigo" dark dense class="mb-0">
                  Selecione os pacotes que deseja desativar:
                </v-alert>
                <v-list dense>
                  <template
                    v-for="(linePackage, index3) in componentData.fields[0]
                      .packages"
                  >
                    <v-list-item :key="linePackage.label" dense>
                      <v-list-item-content>
                        <v-row class="align-center" dense>
                          <v-col md="8">
                            {{ linePackage.label }}
                          </v-col>
                          <v-col md="4">
                            <v-switch
                              class="mt-0"
                              dense
                              v-model="linePackage.disable"
                              hide-details
                              inset
                              @change="updatePackageStatus(index, linePackage)"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row v-if="linePackage.disable" dense>
                          <v-col md="12">
                            <v-text-field
                              label="Data de desativação"
                              outlined
                              dense
                              :value="componentData.fields[0].deactivationDate"
                              readonly
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="
                        index3 < componentData.fields[0].packages.length - 1
                      "
                      :key="index3"
                    ></v-divider>
                  </template>
                </v-list>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: { DatePicker },
  props: ["fields"],
  data() {
    return {
      packagesSelect: [],
      loading: true,
      lineSelect: {}
    };
  },
  async mounted() {
    this.lineSelect = this.fields.packages;
    await this.loadingFilters().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
    this.updateValues();
  },
  computed: {
    ...mapState("packages", ["packages", "lines", "packageTypes"]),
    ...mapGetters({
      name: "forms/getItemName",
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("interaction", ["updateFieldsPackages"]),
    ...mapActions("solicitation", ["updateSolicitationValidatePackagesValues"]),
    ...mapActions("packages", ["loadingFilters", "loadPackages", "loadLines"]),

    async updatePackageStatus(index, line) {
      this.lineSelect[index].packages.map(e => {
        if (e.id_pacote == line.id_pacote) {
          e.disable = line.disable;
        }
      });
      this.normalize(this.lineSelect);
    },

    updateValues() {
      this.normalize(this.lineSelect);
    },

    updateDate(index, line) {
      this.lineSelect[index].deactivationDate = line.deactivationDate;
      this.normalize(this.lineSelect);
    },

    normalize(generateObject) {
      let sendPackagesToDisable = [];
      generateObject.map(e => {
        let packagesToDisable = [];
        e.fields.map(packageStatus => {
          packageStatus.packages.map(packageData => {
            if (packageData.disable === true) {
              packagesToDisable.push({ id_pacote: packageData.package_id });
            }
            return packagesToDisable;
          });
        });

        let lines = {
          line_id: e.line_id,
          deactivationDate: e.deactivationDate ? e.deactivationDate : "",
          packages: packagesToDisable
        };
        sendPackagesToDisable.push(lines);
        return sendPackagesToDisable;
      });
      this.setFieldValue(sendPackagesToDisable);
    },

    setFieldValue(fields) {
      this.updateFieldsPackages(fields);
    }
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
